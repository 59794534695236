import { h } from "preact"

function DeFiLinkIconMobile(){
  return (
    <svg width="254" height="26" viewBox="0 0 254 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M32.5085 18.1122C29.7345 18.1122 27.7406 15.9455 27.7406 13.3455C27.7406 10.7455 29.7345 8.49219 32.5085 8.49219C34.2423 8.49219 35.3693 9.09885 36.2362 10.0522L34.9358 11.4389C34.329 10.7455 33.6355 10.3122 32.5085 10.3122C30.9481 10.3122 29.8211 11.6122 29.8211 13.2589C29.8211 14.9055 30.9481 16.2922 32.5952 16.2922C33.6355 16.2922 34.329 15.8589 35.0225 15.1655L36.3229 16.4655C35.3693 17.3322 34.329 18.1122 32.5085 18.1122Z" fill="#002D74"/>
      <path d="M40.3972 18.1125H38.23V8.40585H40.3972V10.6592C41.004 9.27252 42.0443 8.31919 43.6914 8.40585V10.6592H43.6047C41.7842 10.6592 40.4839 11.8725 40.4839 14.2992V18.1125H40.3972Z" fill="#002D74"/>
      <path d="M54.7877 8.40625L50.1065 20.7129H47.9393L48.8929 18.1129L44.9052 8.40625H47.1591L49.9331 15.5996L52.5338 8.40625H54.7877Z" fill="#002D74"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M58.4287 16.4663C59.0355 17.3329 59.9891 18.1129 61.5495 18.1129C63.7168 18.1129 65.7973 16.2929 65.884 13.2596C65.884 10.1396 63.7168 8.40625 61.5495 8.40625C60.0758 8.40625 59.1222 9.18625 58.4287 10.1396V8.40625H56.2615V20.7129H58.4287V16.4663ZM58.342 13.1729C58.342 11.4396 59.5557 10.2263 61.0294 10.2263C62.5031 10.2263 63.7168 11.4396 63.7168 13.1729C63.7168 14.9929 62.5031 16.1196 61.0294 16.1196C59.6424 16.1196 58.342 14.9062 58.342 13.1729Z" fill="#002D74"/>
      <path d="M70.4785 10.3997V14.9064C70.4785 15.7731 70.912 16.0331 71.6055 16.0331H72.4724V17.9397H70.9987C69.4383 17.9397 68.3113 17.2464 68.3113 15.2531V10.3997H67.0977V8.57974H68.3113V6.06641H70.3919V8.57974H72.3857V10.3997H70.4785Z" fill="#002D74"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M74.2062 13.3455C74.2062 15.9455 76.2867 18.1122 79.1475 18.1122C82.0082 18.1122 84.1755 15.8589 84.0888 13.3455C84.0888 10.6589 82.0082 8.49219 79.1475 8.49219C76.2867 8.49219 74.2062 10.6589 74.2062 13.3455ZM76.3734 13.2589C76.3734 11.6122 77.4137 10.3122 79.1475 10.3122C80.8813 10.3122 82.0082 11.6122 82.0082 13.3455C82.0082 14.9922 80.968 16.2922 79.2342 16.2922C77.5871 16.2922 76.3734 14.9055 76.3734 13.2589Z" fill="#002D74"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M99.5194 13.3455C99.5194 15.9455 101.6 18.1122 104.461 18.1122C107.321 18.1122 109.402 15.8589 109.402 13.3455C109.402 10.6589 107.321 8.49219 104.461 8.49219C101.6 8.49219 99.5194 10.6589 99.5194 13.3455ZM101.687 13.2589C101.687 11.6122 102.727 10.3122 104.461 10.3122C106.108 10.3122 107.321 11.6122 107.321 13.3455C107.321 14.9922 106.281 16.2922 104.547 16.2922C102.9 16.2922 101.687 14.9055 101.687 13.2589Z" fill="#002D74"/>
      <path d="M122.059 8.40625C124.053 8.40625 125.266 9.70625 125.266 11.9596V18.1129H123.099V12.6529C123.099 11.1796 122.405 10.3996 121.192 10.3996C120.065 10.3996 119.198 11.1796 119.198 12.7396V18.1996H117.031V12.7396C117.031 11.2663 116.337 10.4862 115.123 10.4862C113.91 10.4862 113.13 11.3529 113.13 12.8263V18.2863H110.962V8.40625H113.13V9.96625C113.736 9.09958 114.517 8.40625 115.99 8.40625C117.291 8.40625 118.244 9.01292 118.764 10.0529C119.718 9.09958 120.672 8.40625 122.059 8.40625Z" fill="#002D74"/>
      <path d="M88.0764 16.1196V17.1596C88.0764 17.2463 88.0764 17.2463 87.9897 17.2463L87.0361 17.7663H86.9495L85.9959 17.3329L85.9092 17.2463V16.2063C85.9092 16.2063 85.9092 16.1196 85.9959 16.1196L86.9495 15.5996H87.0361L87.9897 16.1196C87.9897 16.0329 88.0764 16.1196 88.0764 16.1196Z" fill="#002D74"/>
      <path d="M94.4914 18.1135C91.7174 18.0268 89.7235 15.8601 89.8102 13.1735C89.8969 10.4868 91.9775 8.32014 94.7515 8.40681C96.4853 8.40681 97.6123 9.10014 98.3925 10.0535L97.0054 11.4401C96.3986 10.7468 95.7051 10.2268 94.6648 10.2268C93.1044 10.2268 91.9775 11.5268 91.9775 13.1735C91.9775 14.8201 93.0177 16.2068 94.6648 16.2068C95.7051 16.2068 96.3986 15.7735 97.0921 15.0801L98.3925 16.3801C97.4389 17.4201 96.3119 18.1135 94.4914 18.1135Z" fill="#002D74"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.4027 0.519531L0 6.49953V18.5462L10.4027 24.5262L20.8055 18.5462V6.49953L10.4027 0.519531ZM6.32833 5.71953H14.5638L15.5174 9.87953H5.37474L6.32833 5.71953ZM7.88874 19.8462L9.62253 18.1995V17.3329L7.80205 15.5995V12.8262L5.37474 11.2662L2.60068 13.3462L6.32833 19.8462H7.88874ZM8.40887 15.4262L8.66894 12.8262L7.80205 10.4862H13.0901L12.2232 12.8262L12.4833 15.4262H10.4027H8.40887ZM13.0901 19.8462H14.5638L18.2915 13.3462L15.5174 11.2662L13.0901 12.8262V15.5995L11.2696 17.3329V18.1995L13.0901 19.8462Z" fill="#002D74"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.4027 5.71953V0.519531L20.8054 6.49953V18.5462L10.4027 24.5262V15.4262H12.4833L12.2232 12.8262L13.0901 10.4862H10.4027V9.87953H15.4307L14.4771 5.71953H10.4027ZM13.0901 19.8462H14.5638L18.2914 13.3462L15.5174 11.2662L13.0901 12.8262V15.5995L11.2696 17.3329V18.1995L13.0901 19.8462Z" fill="url(#paint0_linear_8223_38843)"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M8.66894 12.8262L8.40887 15.4262H10.4027V24.5262L0 18.5462V6.49953L10.4027 0.519531V5.71953H6.32833L5.37474 9.87953H10.4027V10.4862H7.80205L8.66894 12.8262ZM7.88874 19.8462L9.62253 18.1995V17.3329L7.80205 15.5995V12.8262L5.37474 11.2662L2.60068 13.3462L6.32833 19.8462H7.88874Z" fill="url(#paint1_linear_8223_38843)"/>
      <path d="M133.588 0V25.3067" stroke="#B6B8BA" stroke-width="0.55"/>
      <path d="M196.958 18.2858H195.051L192.797 11.7858L190.543 18.2858H188.636L185.255 8.31909H187.596L189.676 14.9924L191.93 8.23242H193.838L196.091 14.9924L198.172 8.31909H200.426L196.958 18.2858Z" fill="#B6B8BA"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M208.661 15.9458L209.528 18.1991H211.869L207.621 8.23242H205.627L201.38 18.1991H203.633L204.5 15.9458H208.661ZM205.194 14.0391L206.494 10.8324L207.795 14.0391H205.194Z" fill="#B6B8BA"/>
      <path d="M214.73 18.1991V8.23242H216.897V16.1191H221.838V18.1991H214.73Z" fill="#B6B8BA"/>
      <path d="M224.872 18.1991V8.23242H227.04V16.1191H231.981V18.1991H224.872Z" fill="#B6B8BA"/>
      <path d="M235.102 18.1991V8.23242H242.557V10.1391H237.269V12.1324H241.95V14.0391H237.269V16.1191H242.644V18.1991H235.102Z" fill="#B6B8BA"/>
      <path d="M250.532 10.3124V18.1991H248.365V10.3124H245.331V8.23242H253.567V10.2258H250.532V10.3124Z" fill="#B6B8BA"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M146.158 8.23242H142.257V18.1124H146.158C149.279 18.1124 151.446 15.9458 151.446 13.1724C151.446 10.3991 149.279 8.23242 146.158 8.23242ZM149.192 13.2591C149.192 14.9924 147.979 16.2058 146.158 16.2058H144.511V10.2258H146.158C147.979 10.2258 149.192 11.5258 149.192 13.2591Z" fill="#B6B8BA"/>
      <path d="M154.134 8.23242H161.589V10.1391H156.301V12.1324H160.982V14.0391H156.301V16.1191H161.676V18.1991H154.134V8.23242Z" fill="#B6B8BA"/>
      <path d="M164.883 8.23242H172.425V10.2258H167.051V12.3058H171.818V14.2991H167.051V18.1991H164.883V8.23242Z" fill="#B6B8BA"/>
      <path d="M175.719 8.23242H177.887V18.1991H175.719V8.23242Z" fill="#B6B8BA"/>
      <defs>
        <linearGradient id="paint0_linear_8223_38843" x1="19.7421" y1="21.9636" x2="19.7421" y2="3.02628" gradientUnits="userSpaceOnUse">
          <stop stop-color="#002D74"/>
          <stop offset="1" stop-color="#002D74" stop-opacity="0.01"/>
        </linearGradient>
        <linearGradient id="paint1_linear_8223_38843" x1="9.31608" y1="21.9636" x2="9.31608" y2="3.02628" gradientUnits="userSpaceOnUse">
          <stop stop-color="#002D74" stop-opacity="0.01"/>
          <stop offset="1" stop-color="#002D74"/>
        </linearGradient>
      </defs>
    </svg>

  )
}

export default DeFiLinkIconMobile
